function setCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function removeHubspotIdentityCookie() {
  // console.log('removeHubspotIdentityCookie');
  setCookie('hubspotutk', '', -1);
}
function disableHubpsotTracking() {
  // console.log('disableHubspotTracking');
  var _hsp = window._hsp = window._hsp || [];
  var _hsq = window._hsq = window._hsq || [];
  var _hsp = window._hsp = window._hsp || [];
  _hsp.push(['revokeCookieConsent']);
  _hsq.push(['doNotTrack']);

  // facebook cookie is not needed in anay case 
  removeCookie('_fbp', '.' + window.location.hostname)
}
function enableHubspotTracking() {
  // console.log('enableHubspotTracking');
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(['doNotTrack', { track: true }]);
  setCookie('__hs_do_not_track', '', -1);
  setCookie('hubspotutk', '', -1);

  // facebook cookie is not needed in anay case 
  removeCookie('_fbp', '.' + window.location.hostname)
}

function removeCookie(cookie, domain = window.location.hostname, path = '/') {
  document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970;domain=${domain};path=${path}`
}

function updateHubspotTrackingUser(email, userId, userName) {
  // console.log('updateHubspotTrackingUser');
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(["identify", {
    email: email,
    id: userId,
    name: userName,
  }]);
}
function cookieTracking() {
  var _hsp = window._hsp = window._hsp || [];
  _hsp.push(['addPrivacyConsentListener', function (consent) {
    console.log(`analytics: ${consent.categories.analytics}`);
    console.log(`advertisement: ${consent.categories.advertisement}`);
    console.log(`functionality: ${consent.categories.functionality}`);
  }]);
}